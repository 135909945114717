<template>
  <div class="avisInterviews">
    <EntitiesInterviews entities="avis" entity="avi" />
  </div>
</template>
<script>
  import EntitiesInterviews from "../../components/entities/EntitiesInterviews";
  export default {
    components: {
      EntitiesInterviews
    }
  }
</script>